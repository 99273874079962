import React, { useState, useEffect } from "react";
import axios from "axios";
import { useStore } from "./store";
import ImageViewComponent from "./ImageViewComponent";
import SimpleModal from "./SimpleModal";
import { v4 as uuidv4 } from "uuid"; // For generating unique user IDs
import { useLoading } from "./App";
import "./PDFUploadPage.css"; // CSS 파일 임포트

const PDFPageComponent = () => {
  // State for managing file uploads
  const [files, setFiles] = useState<File[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const { isLoading, setIsLoading } = useLoading(); // App 컴포넌트로부터 로딩 상태를 가져옵니다.
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // 모달을 닫는 함수
  const closeModal = () => {
    setModalVisible(false);
  };
  // Zustand store integration
  const {
    currentFile,
    fileName,
    currentPage,
    numPages,
    images,
    selections,
    setPDFInfo,
    setCurrentPage,
    clearPointsAndSelections,
    setFile,
    userId,
    setUserId,
  } = useStore();

  useEffect(() => {
    console.log("Loading State Changed:", isLoading);
  }, [isLoading]); // isLoading 상태가 변할 때마다 로그를 출력

  useEffect(() => {
    if (!userId) {
      const newUserId = uuidv4();
      setUserId(newUserId);
      // Optionally, send this newUserId to your backend for tracking
    }
  }, [userId, setUserId]);

  useEffect(() => {
    const newUserId = uuidv4();
    setUserId(newUserId);
    // Optionally, send this newUserId to your backend for tracking
  }, []); // useEffect의 dependency array를 비워서 컴포넌트가 마운트 될 때만 실행되도록 합니다.

  useEffect(() => {
    const handleUnload = async () => {
      if (userId) {
        try {
          // 비동기 요청을 여기서 보냅니다
          await axios.post(
            `https://api.multicaptures.com/cleanup_data?userId=${userId}`,
          );
        } catch (error) {
          console.error("Error during session cleanup:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [userId]); // Dependency array에 userId를 포함시켜 userId가 변경될 때 리스너도 업데이트 됩니다.

  // Handle file input change and set files
  // Handle file input change and set files
  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files);
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes

      // Check if any selected file exceeds the maximum size
      const isFileTooLarge = fileList.some((file) => file.size > maxSize);

      if (isFileTooLarge) {
        // Show an alert if any file is too large
        alert("One or more files exceed the maximum size limit of 5MB.");
      } else {
        // Proceed with setting the files if all files are within the size limit
        setFiles(fileList);
        setCurrentFileIndex(0);
        setCurrentPage(1);
      }
    }
  };

  // Effect for uploading files upon selection or change in current file index
  useEffect(() => {
    if (files.length > 0 && currentFileIndex < files.length && userId != null) {
      handleFileUpload(userId, files[currentFileIndex]);
    }
  }, [userId, files, currentFileIndex]);

  // 파일 업로드 및 처리 함수
  const handleFileUpload = async (userId: string, file: File) => {
    setIsLoading(true); // 처리 시작
    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", userId);

    try {
      const uploadResponse = await axios.post(
        "https://api.multicaptures.com/upload",
        formData,
      );
      setFile(file);
      setPDFInfo(
        uploadResponse.data.fileName,
        uploadResponse.data.imageArray.length,
        uploadResponse.data.imageArray,
      );
      setCurrentPage(1);
    } catch (error) {
      console.error("File upload error:", error);
    } finally {
      setIsLoading(false); // 처리 완료
    }
  };

  // Process the next file in the queue
  const handleNextFile = async () => {
    const nextIndex = currentFileIndex + 1;
    if (nextIndex < files.length) {
      setIsLoading(true); // 로딩 시작
      setCurrentFileIndex(nextIndex);
      clearPointsAndSelections();
      // handleFileUpload 함수가 비동기로 작동하며 완료될 때까지 대기
      await handleFileUpload(userId as string, files[nextIndex])
        .then(() => {
          setIsLoading(false); // 로딩 종료
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          setIsLoading(false); // 에러 발생 시 로딩 종료
        });
    } else {
      console.log("All files have been processed.");
      setIsLoading(false); // 모든 파일 처리 완료
    }
  };

  const handleSave = async () => {
    if (selections.length > 0) {
      const payload = {
        fileName: fileName,
        pageNumber: currentPage,
        selections: selections,
      };

      try {
        await axios.post(
          "https://api.multicaptures.com/save_selections/",
          payload,
        );
        console.log("Selections saved successfully.");
        if (currentPage === numPages) {
          setModalMessage(
            "Work on this file is completed. Press M to start working on the next file.",
          );
          setModalVisible(true);
        }
        // Scroll to the top of the page
        window.scrollTo(0, 0); // 페이지 상단으로 스크롤 이동
      } catch (error) {
        console.error("Error saving selections:", error);
      }
    }

    clearPointsAndSelections();
    setCurrentPage(Math.min(currentPage + 1, numPages));
  };

  // Merge images in the current PDF
  // const mergeImagesInPdf = async (userId: string) => {
  //   if (currentFile) {
  //     setIsLoading(true); // 병합 처리 시작
  //     const formData = new FormData();
  //     formData.append("file", currentFile);
  //     formData.append("userId", userId);
  //     try {
  //       const response = await axios.post(
  //         "https://api.multicaptures.com/process_pdf/",
  //         formData,
  //       );
  //       console.log("Merged PDF:", response.data.combined_image_paths);
  //     } catch (error) {
  //       console.error("Error merging PDF:", error);
  //     } finally {
  //       handleNextFile(); // 다음 파일 처리 시작
  //     }
  //   }
  // };

  // Navigate to the previous page
  // const handlePreviousPage = () => {
  //   clearPointsAndSelections();
  //   setCurrentPage(Math.max(currentPage - 1, 1));
  // };

  // Navigate to the next page
  // const handleNextPage = () => {
  //   clearPointsAndSelections();
  //   setCurrentPage(Math.min(currentPage + 1, numPages));
  // };

  const downloadMergedFilesAsZip = async (userId: string) => {
    if (currentFile) {
      setIsLoading(true); // 처리 시작
      const formData = new FormData();
      formData.append("file", currentFile);
      formData.append("userId", userId);
      try {
        const response = await axios.post(
          "https://api.multicaptures.com/extract_and_download/",
          formData,
          { responseType: "blob" }, // 중요: 응답을 Blob 형태로 받음
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const downloadFileName = fileName.replace(/\.[^/.]+$/, "") + ".zip"; // 확장자 제거 후 .zip 추가
        link.setAttribute("download", downloadFileName); // 수정된 부분: fileName을 사용하여 다운로드 파일 이름 설정
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link); // 안전하게 요소 제거
        }

        window.URL.revokeObjectURL(url);
        console.log("Downloaded:", downloadFileName);
      } catch (error) {
        console.error("Error downloading ZIP file:", error);
      } finally {
        handleNextFile(); // 다음 파일 처리 시작
      }
    }
  };

  // Keyboard shortcuts for merge and save
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.key === "M" || event.key === "m") {
        if (currentFileIndex === files.length - 1) {
          await downloadMergedFilesAsZip(userId as string);
          setModalMessage(
            "All files have been processed. You can upload new files to continue.",
          );
          setModalVisible(true);
        } else {
          await downloadMergedFilesAsZip(userId as string);
        }
      } else if (event.key === "S" || event.key === "s") {
        await handleSave();
        if (currentPage === numPages) {
          setModalMessage(
            "Work on this file is completed. Press M to start working on the next file.",
          );
          setModalVisible(true);
        }
      } else if (event.key === "Enter" && modalVisible) {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    downloadMergedFilesAsZip,
    handleSave,
    closeModal,
    userId,
    currentFileIndex,
    files.length,
    currentPage,
    numPages,
    modalVisible,
  ]);

  return (
    <div>
      <div className="stickyHeader">
        <input type="file" multiple onChange={handleFilesChange} />
        {files.length > 0 && (
          <div>
            <h3>
              Working on file {currentFileIndex + 1} of {files.length}:{" "}
              {files[currentFileIndex].name}
            </h3>
            <p>
              Page {currentPage} of {numPages}
            </p>
          </div>
        )}
      </div>
      <ImageViewComponent currentPage={currentPage} images={images} />
      {/*<div>
        <button onClick={handlePreviousPage} disabled={currentPage <= 1}>
          Previous
        </button>
        <button onClick={handleNextPage} disabled={currentPage >= numPages}>
          Next
        </button>
        <button onClick={handleSave}>Save</button>
        <button onClick={() => mergeImagesInPdf(userId as string)}>
          Merge
        </button>
        <button onClick={handleNextFile} disabled={isLoading}>
          Process Next File
        </button>
      </div>*/}
      <SimpleModal
        isVisible={modalVisible}
        message={modalMessage}
        onClose={closeModal}
      />
    </div>
  );
};

export default PDFPageComponent;
