// App.tsx

import React, { createContext, useContext, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; // HashRouter로 변경
import StartPage from "./StartPage";
import PDFUploadPage from "./PDFUploadPage";
import DetectionStartPage from "./DetectionStartPage";
import SegmentationStartPage from "./SegmentationStartPage";
import CircularProgress from "@mui/material/CircularProgress";
import "./App.css";

// Create a context for the loading state
const LoadingContext = createContext({
  isLoading: false,
  setIsLoading: (state: boolean) => {},
});

export function useLoading() {
  return useContext(LoadingContext);
}

function App() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <Router>
        {" "}
        <div className="App">
          {isLoading && (
            <div className="loading-overlay">
              <CircularProgress size={60} />
            </div>
          )}
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/multicapture" element={<PDFUploadPage />} />
            <Route path="/detectioncapture" element={<DetectionStartPage />} />
            <Route
              path="/segmentationcapture"
              element={<SegmentationStartPage />}
            />
          </Routes>
        </div>
      </Router>
    </LoadingContext.Provider>
  );
}

export default App;
