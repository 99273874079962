// StartPage.tsx

import { Link } from "react-router-dom";

const StartPage = () => {
  return (
    <div className="start-page">
      <h1>Select Your Task</h1>
      <div>
        <Link to="/multicapture">MultiCapture</Link>
      </div>
      {/* <div>
        <Link to="/detectioncapture">Make Detection Dataset</Link>
      </div>
      <div>
        <Link to="/segmentationcapture">Make Segmentation Dataset</Link>
      </div> */}
    </div>
  );
};

export default StartPage;
