import { create } from "zustand";
// import { persist, createJSONStorage } from "zustand/middleware";
import { persist, createJSONStorage } from "zustand/middleware";
import { Selection, Point } from "./types";

interface PDFState {
  currentFile: File | null;
  fileName: string;
  numPages: number;
  currentPage: number;
  images: string[];
  points: Point[];
  selections: Selection[];
  userId: string | null;
  setFile: (file: File) => void;
  setPDFInfo: (fileName: string, numPages: number, images: string[]) => void;
  setCurrentPage: (page: number) => void;
  setImages: (images: string[]) => void;
  addPoint: (point: Point) => void;
  clearPointsAndSelections: () => void;
  setUserId: (userId: string) => void;
}

// 상태의 일부를 나타내는 새로운 인터페이스 정의
interface PartialPDFState {
  userId: string | null;
}

const sessionStorageAdapter = createJSONStorage(() => sessionStorage);

export const useStore = create<PDFState>()(
  persist(
    (set) => ({
      currentFile: null,
      fileName: "",
      numPages: 0,
      currentPage: 1,
      images: [],
      points: [],
      selections: [],
      userId: null,
      setFile: (currentFile) => set({ currentFile }),
      setPDFInfo: (fileName, numPages, images) =>
        set({ fileName, numPages, images }),
      setCurrentPage: (page) => set({ currentPage: page }),
      setImages: (images) => set({ images }),
      addPoint: (point) =>
        set((state) => {
          const newPoints = [...state.points, point];
          let newSelections = [...state.selections];
          if (newPoints.length % 2 === 0) {
            const start = newPoints[newPoints.length - 2];
            const end = point;
            const selection: Selection = {
              start: {
                x: Math.min(start.x, end.x),
                y: Math.min(start.y, end.y),
              },
              end: {
                x: Math.max(start.x, end.x),
                y: Math.max(start.y, end.y),
              },
            };
            newSelections.push(selection);
          }
          return { ...state, points: newPoints, selections: newSelections };
        }),
      clearPointsAndSelections: () => set({ points: [], selections: [] }),
      setUserId: (userId) => set({ userId }),
    }),
    // storage 옵션 설정
    {
      name: "pdf-state",
      storage: sessionStorageAdapter,
      partialize: (state: PDFState): PartialPDFState => ({
        userId: state.userId,
      }),
    },
  ),
);
