import React from "react";

interface SimpleModalProps {
  isVisible: boolean;
  message: string;
  onClose: () => void;
}

const SimpleModal: React.FC<SimpleModalProps> = ({
  isVisible,
  message,
  onClose,
}) => {
  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        zIndex: 1000,
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <p>{message}</p>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default SimpleModal;
