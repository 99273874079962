// ImageComponent.tsx
import React, {
  CSSProperties,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
} from "react";
import { useStore } from "./store";

interface ImageComponentProps {
  src: string;
  alt: string;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ src, alt }) => {
  const { addPoint, points, selections } = useStore();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // 이 부분에서 MouseEvent를 직접 DOM의 이벤트로 명시합니다.
  const updateMousePosition = (ev: MouseEvent) => {
    setMousePosition({
      x: ev.clientX,
      y: ev.clientY,
    });
  };

  const handleClick = (e: ReactMouseEvent<HTMLImageElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    addPoint({ x, y });
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "c" || e.key === "C") {
        // 가능하면 ref를 사용하는 방식으로 변경하세요.
        const rect = document.querySelector("img")?.getBoundingClientRect();
        if (rect) {
          const x = ((mousePosition.x - rect.left) / rect.width) * 100;
          const y = ((mousePosition.y - rect.top) / rect.height) * 100;
          addPoint({ x, y });
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [mousePosition.x, mousePosition.y]); // Dependency array includes mouse position to keep track of its changes

  const imageStyle: CSSProperties = {
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    height: "auto",
  };

  return (
    <div style={{ position: "relative", width: "fit-content", margin: "auto" }}>
      <img style={imageStyle} src={src} alt={alt} onClick={handleClick} />
      {points.map((point, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            left: `${point.x}%`,
            top: `${point.y}%`,
            width: "5px",
            height: "5px",
            borderRadius: "50%",
            backgroundColor: "red",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
          }}
        />
      ))}
      {selections.map((selection, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            left: `${selection.start.x}%`,
            top: `${selection.start.y}%`,
            width: `${selection.end.x - selection.start.x}%`,
            height: `${selection.end.y - selection.start.y}%`,
            border: "2px solid blue",
            pointerEvents: "none",
          }}
        />
      ))}
    </div>
  );
};

export default ImageComponent;
