import React, { useState, useEffect } from "react";
import axios from "axios";
import { useStore } from "./store";
import ImageViewComponent from "./ImageViewComponent";

const PDFPageComponent = () => {
  // State for managing file uploads
  const [files, setFiles] = useState<File[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  // Zustand store integration
  const {
    currentFile,
    fileName,
    currentPage,
    numPages,
    images,
    selections,
    setPDFInfo,
    setCurrentPage,
    clearPointsAndSelections,
    setFile,
    // addPoint, // Assuming you might need this for adding points on image click
    // // Add any other actions you need from your store
  } = useStore();

  // Handle file input change and set files
  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files);
      setFiles(fileList);
      setCurrentFileIndex(0); // Start processing from the first file
      setCurrentPage(1);
    }
  };

  // Effect for uploading files upon selection or change in current file index
  useEffect(() => {
    if (files.length > 0 && currentFileIndex < files.length) {
      handleFileUpload(files[currentFileIndex]);
    }
  }, [files, currentFileIndex]);

  // Uploads the current file and updates the store with the response
  const handleFileUpload = async (file: File) => {
    clearPointsAndSelections();
    const formData = new FormData();
    formData.append("file", file);

    try {
      const uploadResponse = await axios.post(
        "http://localhost:8001/upload/",
        formData,
      );
      setFile(file);
      setPDFInfo(
        uploadResponse.data.fileName,
        uploadResponse.data.imageArray.length,
        uploadResponse.data.imageArray,
      );
      setCurrentPage(1); // Start displaying from the first page of the new upload
    } catch (error) {
      console.error("File upload error:", error);
    }
  };

  // Process the next file in the queue
  const handleNextFile = () => {
    const nextIndex = currentFileIndex + 1;
    if (nextIndex < files.length) {
      setCurrentFileIndex(nextIndex);
      clearPointsAndSelections();
    } else {
      console.log("All files have been processed.");
    }
  };

  // Save selections to the server
  const handleSave = async () => {
    if (selections.length > 0) {
      const payload = {
        fileName: fileName,
        pageNumber: currentPage,
        selections: selections,
      };

      try {
        await axios.post("http://localhost:8001/save_selections/", payload);
        console.log("Selections saved successfully.");
      } catch (error) {
        console.error("Error saving selections:", error);
      }
    }

    clearPointsAndSelections();
    setCurrentPage(Math.min(currentPage + 1, numPages)); // Optionally move to the next page after saving
  };

  // Merge images in the current PDF
  const mergeImagesInPdf = async () => {
    if (currentFile) {
      const formData = new FormData();
      formData.append("file", currentFile);

      try {
        const response = await axios.post(
          "http://localhost:8001/process_pdf/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        console.log("Merged PDF:", response.data.combined_image_paths);
      } catch (error) {
        console.error("Error merging PDF:", error);
      } finally {
        handleNextFile(); // Proceed to the next file after merging, regardless of success or failure
      }
    }
  };

  // Navigate to the previous page
  const handlePreviousPage = () => {
    clearPointsAndSelections();
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  // Navigate to the next page
  const handleNextPage = () => {
    clearPointsAndSelections();
    setCurrentPage(Math.min(currentPage + 1, numPages));
  };

  // Keyboard shortcuts for merge and save
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.key === "M" || event.key === "m") {
        await mergeImagesInPdf();
      } else if (event.key === "S" || event.key === "s") {
        await handleSave();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [mergeImagesInPdf, handleSave]);

  return (
    <div>
      <input type="file" multiple onChange={handleFilesChange} />
      <ImageViewComponent currentPage={currentPage} images={images} />
      <button onClick={handlePreviousPage} disabled={currentPage <= 1}>
        Previous
      </button>
      <button onClick={handleNextPage} disabled={currentPage >= numPages}>
        Next
      </button>
      <button onClick={handleSave}>Save</button>
      <button onClick={mergeImagesInPdf}>Merge</button>
      <button onClick={handleNextFile}>Process Next File</button>
      <p>
        Page {currentPage} of {numPages}
      </p>
    </div>
  );
};

export default PDFPageComponent;
