// ImageViewComponent.tsx
import React, { CSSProperties } from "react";
import ImageComponent from "./ImageComponent";

interface ImageViewComponentProps {
  currentPage: number;
  images: string[];
}

const ImageViewComponent: React.FC<ImageViewComponentProps> = ({
  currentPage,
  images,
}) => {
  if (images.length === 0) return null;

  // ImageViewComponent 컨테이너에 대한 스타일입니다.
  // ImageComponent가 이미지의 크기에 맞춰 렌더링되도록 maxWidth와 maxHeight를 설정합니다.
  const containerStyle: CSSProperties = {
    position: "relative", // SelectionArea가 이 컨테이너에 상대적으로 위치하도록 설정
    width: "100%", // 부모 요소의 전체 너비를 사용
    margin: "auto", // 자동 마진으로 가운데 정렬
    border: "1px solid #ddd", // 테두리 스타일
    padding: "5px", // 패딩 스타일
    boxSizing: "border-box", // 패딩과 테두리를 너비에 포함
    display: "flex", // 이미지를 가운데 정렬하기 위해 flexbox를 사용
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={containerStyle}>
      {/* ImageComponent는 이미지를 렌더링하고, SelectionArea는 절대 위치로 겹쳐지게 됩니다. */}
      <ImageComponent
        src={`data:image/png;base64,${images[currentPage - 1]}`}
        alt={`Page ${currentPage}`}
      />
    </div>
  );
};

export default ImageViewComponent;
